import { Routes, Route, useLocation, Link } from "react-router-dom";
import "./i18n/config";
import io from "socket.io-client";
import CardGenerator from "./CardGenerator";
import Admin from "./Admin";
import AdminOLD from "./AdminOLD";
import Result from "./Result";
import Header from "./Header";
import Login from "./Login";
import ScrollToTop from "./utils/scrollToTop";
import { AnimatePresence } from "framer-motion";
import Terms from "views/Terms";
import CookiePolicy from "views/CookiePolicy";
import PrivacyPolicy from "views/PrivacyPolicy";

function App() {
  const location = useLocation();

  const URL =
    process.env.NODE_ENV === "production" ? undefined : "http://localhost:4000";
  const socket = io(URL);

  return (
    <>
      <ScrollToTop />
      <Header />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<CardGenerator socket={socket} />} />
          <Route
            path="/result/:userId"
            element={<Result type="sender" socket={socket} />}
          />
          <Route
            path="/happybday/:userId"
            element={<Result type="recipient" socket={socket} />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/adminOLD" element={<AdminOLD />} />

          <Route path="/terms" element={<Terms />} />
          <Route path="/cookies" element={<CookiePolicy />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
