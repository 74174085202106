import { createRoot } from "react-dom/client";
import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root");
const root = createRoot(container);
// Redirect to HTTPS if not already on HTTPS and not on localhost
if (
  window.location.protocol !== "https:" &&
  window.location.hostname !== "localhost"
) {
  window.location.href =
    "https:" + window.location.href.substring(window.location.protocol.length);
}

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
