import React, { useEffect, useCallback, useRef, createRef } from "react";
import gsap, { Power4 } from "gsap";

export const dropIn = {
  hidden: {
    x: "-10vh",
    opacity: 0,
  },
  visible: {
    x: "0",

    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.6,
      type: "spring",
      bounce: 0.3,
    },
    transitionEnd: {
      // temp workaround to fix trailing opacity and transform
      opacity: 1,
      x: 0,
    },
  },
  exit: {
    x: "30vh",
    opacity: 0,
    transition: {
      duration: 0.5,
      type: "spring",
      bounce: 0.3,
    },
  },
};

export const fadeInOut = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1.5,
    },
  },
  exit: {
    opacity: 0,
  },
};

export const blurInOut = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
  },
};

export const staggerItems = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,

    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3,
    },
  },
};

export const item = {
  hidden: { opacity: 0, y: "-20px", scale: 0.9 },
  show: { opacity: 1, y: "0", scale: 1 },
};

export const staggerItemsFade = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,

    transition: {
      delay: 0.3,
      when: "beforeChildren",
      staggerChildren: 0.2,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

export const staggerItemsDelay = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,

    transition: {
      delay: 0.8,
      when: "beforeChildren",
      staggerChildren: 0.2,
      delayChildren: 0.5,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

export const itemFade = {
  hidden: { opacity: 0, y: "-10vh" },
  show: {
    opacity: 1,
    y: "0",
    transition: { type: "spring", bounce: 0 },
  },
};

export const staggeritemFadeScale = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,

    transition: {
      delay: 0.1,
      when: "beforeChildren",
      staggerChildren: 0.3,
      staggerDirection: -1,
    },
  },
};

export const itemFadeScale = {
  hidden: { opacity: 0, scale: 0.8 },
  show: {
    opacity: 1,
    scale: [1, 1.1, 1],
    transition: {
      delay: 0.1,
      when: "beforeChildren",
      staggerChildren: 0.3,
      type: "spring",
      mass: 0.5,
    },
  },
};

//  NEW

const transition = {
  duration: 0.5,
  type: "spring",
};

export const scaleInOut = {
  hidden: {
    scale: 0,
    opacity: 0,

    transition: { transition },
  },
  visible: {
    scale: 1,
    opacity: 1,

    transition: { transition },
  },
  exit: {
    scale: 0,
    opacity: 0,

    transition: { transition },
  },
};

export const modalDrop = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      delay: 0.3,
      duration: 0.1,
      type: "spring",
      bounce: 0.5,
      damping: 25,
      stiffness: 500,
      delayChildren: 0.3,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
    transition: {
      duration: 0.5,
      type: "spring",
      bounce: 0.3,
    },
  },
};

export const cardVariants = {
  offscreen: {
    y: 300,
  },
  onscreen: {
    y: 50,
    rotate: -10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

export const bounceIn = {
  hidden: { opacity: 0, scale: 0.5 },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      ease: [0, 0.71, 0.2, 1.01],
      scale: {
        type: "spring",
        damping: 5,
        stiffness: 100,
        restDelta: 0.001,
      },
    },
  },
};

const fadeTransition = { ease: "easeIn", delay: 0.2, duration: 0.5 };
export const itemFadeIn = {
  hidden: { opacity: 0, fadeTransition, scale: 0 },
  show: {
    scale: 1,
    opacity: [0.5, 1],
    transition: fadeTransition,
  },
};

// react-explode

const STROKE_WIDTH = 0.7; //0.5
const RADIUS = 47; //47.5;
const COUNT = 25; //20
const DURATIONS = [0.8, 1]; //[1.2, 3]

export function Boracay({
  size,
  delay,
  repeatDelay,
  repeat,
  style,
  color,
  stroke = STROKE_WIDTH,
  rad = RADIUS,
  duration = DURATIONS,
  onComplete,
  onStart,
  onRepeat,
  className,
}) {
  const linesRefs = useRef([...Array(COUNT)].map(() => createRef()));
  const center = size / 2;
  const strokeWidth = Math.ceil((size * stroke) / 100);

  const explode = useCallback(() => {
    const ease = Power4.easeOut;
    const angle = Math.PI / 8;
    const radius = (size * rad) / 100;
    const durations = duration;
    const center = size / 2;
    const timelines = [];

    const TIME_LINE = gsap.timeline({
      repeat,
      delay,
      repeatDelay,
      onComplete: () => {
        if (typeof onComplete === "function") {
          onComplete();
        }
      },
      onStart,
      onRepeat,
    });

    linesRefs.current.forEach((ref, i) => {
      const timeline = gsap.timeline();
      const x = center + radius * Math.cos(i * angle);
      const y = center + radius * Math.sin(i * angle);
      const start = { x2: x, y2: y };
      const end = { x1: x, y1: y };

      timeline
        .fromTo(
          ref.current,
          durations[0],
          { attr: { x2: center, y2: center } },
          { attr: start, ease }
        )
        .fromTo(
          ref.current,
          durations[1],
          { attr: { x1: center, y1: center } },
          { attr: end, ease },
          `-=${durations[0]}`
        );

      timelines.push(timeline);
    });

    TIME_LINE.add(timelines);
  }, [
    size,
    repeat,
    delay,
    repeatDelay,
    onComplete,
    onStart,
    onRepeat,
    rad,
    duration,
  ]);

  useEffect(() => {
    explode();
  }, []);

  return (
    <svg
      width={size}
      height={size}
      className={className}
      style={{
        ...style,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      {linesRefs.current.map((ref, i) => (
        <line
          x1={center}
          y1={center}
          x2={center}
          y2={center}
          ref={ref}
          key={i}
          strokeWidth={strokeWidth}
          stroke={color}
        />
      ))}
    </svg>
  );
}
