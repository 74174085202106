import { useState, useEffect, useReducer } from "react";
import axios from "axios";

// Styles
import "./assets/styles/style.scss";

function Admin() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem("token");

  async function getData() {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DOMAIN}/getResults`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data);
      setIsLoading(false);
    } catch (error) {
      window.location.href = "/login";
      console.log(error);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="App p-5">
      <div className="container text-center">
        {isLoading ? (
          <h1>LOADING ...</h1>
        ) : (
          <table className="table text-start">
            <thead>
              <tr>
                <th style={{ width: "5%" }} scope="col">
                  #
                </th>
                <th style={{ width: "40%" }} scope="col">
                  Prompt
                </th>
                <th style={{ width: "50%" }} scope="col">
                  Result
                </th>
                <th style={{ width: "5%" }} scope="col">
                  Rating
                </th>
              </tr>
            </thead>
            <tbody>
              {data
                .sort((a, b) => b.created.localeCompare(a.created))
                .map((item, index) => (
                  <tr key={index}>
                    <th scope="row">{data.length - index}</th>
                    <td>
                      {item.prompt}
                      <br />
                      <br />
                      <strong>{convertUtcToSlovenia(item.created)}</strong>
                      <br />
                    </td>
                    <td>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            typeof item.result === "object"
                              ? item.result.content
                              : item.result,
                        }}></div>
                    </td>
                    <td>
                      {item.rating !== null && item.rating.length !== 0 ? (
                        <>
                          <strong>
                            {Math.round(
                              item.rating.reduce((a, b) => a + b, 0) /
                                item.rating.length
                            )}
                          </strong>
                          <small> ({item.rating.length}x)</small>
                        </>
                      ) : (
                        <strong>N/A </strong>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

function convertUtcToSlovenia(utcTimestamp) {
  const createdUtc = new Date(utcTimestamp);
  const createdSlovenia = new Date(createdUtc.getTime() + 0 * 60 * 60 * 1000);
  const createdDateString = createdSlovenia.toLocaleDateString("sl-SI", {
    timeZone: "Europe/Ljubljana",
  });
  const createdTimeString = createdSlovenia.toLocaleTimeString("sl-SI", {
    timeZone: "Europe/Ljubljana",
  });
  return `${createdDateString} ${createdTimeString}`;
}

export default Admin;
