const dataExclude = [
  "Puši",
  "Kurac",
  "Kurec",
  "Kurc",
  "Kurčevo",
  "Fafanje",
  "Fafati",
  "Skurcati",
  "Sranje",
  "Zasral",
  "Posral",
  "Drekač",
  "Drek",
  "Seronja",
  "serji",
  "seri",
  "Anal",
  "Analno",
  "Sodomija",
  "Pornič",
  "Porno",
  "Pornografija",
  "Pizda",
  "Pizdun",
  "Pezde",
  "Popizdi",
  "Napizdi",
  "Razpizdi",
  "Dopizdi",
  "Odpizdi",
  "Pizdarija",
  "Pizdin",
  "Pička",
  "Pičkin",
  "Prasica",
  "Prasec",
  "Prase",
  "Kurba",
  "Kurbin sin",
  "Kurbir",
  "Kurbišče",
  "Kurbarstvo",
  "Kurbarija",
  "Kurva",
  "Jebem ti",
  "Jebi se",
  "Jeba",
  "Jebač",
  "Jebiveter",
  "Jebanje",
  "Jebeno",
  "Jebati",
  "Odjebati",
  "Najebati",
  "Zajebati",
  "Zjeban",
  "Šlatanje",
  "Šlatati",
  "Črnuh",
  "Čefur",
  "Limka",
  "Gaser",
  "Peder",
  "Debil",
  "Idiot",
  "Kreten",
  "Fak",
  "Fukati",
  "Fukanje",
  "Pofukan",
  "Prfuknjen",
  "Fuknjen",
  "Seks",
  "Seksi",
  "Seksanje",
  "Seksati",
  "Alkohol",
  "Droge",
  "Vino",
  "Pivo",
  "Viski",
  "Vodka",
  "Gin",
  "Rum",
  "Liker",
  "Tekila",
  "Marihuana",
  "LSD",
  "Cid",
  "Psilocibin",
  "Ketamin",
  "Psihadeliki",
  "Halucinogen",
  "Ekstazi",
  "MDMA",
  "Speed",
  "spid",
  "Heroin",
  "Kokain",
  "Snifanje",
  "Dildo",
  "Vibrator",
  "Otipavanje",
  "Otipavati",
];

export { dataExclude };
