import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const windowUrl = window.location.search;

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }

  useEffect(() => {
    if (windowUrl === "?lang=en") {
      i18n.changeLanguage("en");
    } else if (windowUrl === "?lang=sl") {
      i18n.changeLanguage("sl");
    }
  }, []);

  return (
    <div className="header d-none">
      <button
        onClick={changeLanguage}
        value="sl"
        type="button"
        className={
          "btn py-0 px-2 " + (currentLanguage === "sl" ? "active" : "")
        }>
        slo
      </button>
      |
      <button
        onClick={changeLanguage}
        value="en"
        type="button"
        className={
          "btn py-0 px-2 " + (currentLanguage === "en" ? "active" : "")
        }>
        en
      </button>
    </div>
  );
};

export default Header;
