import { motion } from "framer-motion";
import {
  bounceIn,
  itemFade,
  itemFadeScale,
  staggerItemsFade,
  itemFadeIn,
} from "utils/animations";
import { Link } from "react-router-dom";
import Footer from "../Footer";

import imgElmLeft from "../assets/images/bg-result-elm-left.svg";
import imgElmBottomRight from "../assets/images/bg-result-elm-bottom-right.svg";
import imgLogoWhite from "../assets/images/logo-white.svg";

const PrivacyPolicy = () => {
  return (
    <motion.div
      variants={staggerItemsFade}
      initial="hidden"
      animate="show"
      exit="exit"
      className="app position-relative">
      <motion.img
        variants={bounceIn}
        className="position-absolute  start-2 top-20 d-none d-xl-block"
        // className="position-absolute h-100 start-2 top-20 d-none d-xl-block"
        style={{ zIndex: "2", height: "200px" }}
        src={imgElmLeft}
        alt={imgElmLeft}
      />
      <Link to="/">
        <motion.div
          variants={itemFade}
          className="hero hero__primary position-relative bg-white text-white text-center pt-3 pt-md-5 pb-6 pb-md-8">
          <motion.img
            variants={itemFade}
            className="position-absolute h-25  "
            style={{ right: "15px", top: "15px", zIndex: "2" }}
            src={imgLogoWhite}
            alt={imgLogoWhite}
          />
        </motion.div>
      </Link>
      <motion.div
        variants={itemFade}
        className="bg-white position-relative"
        style={{ zIndex: "1" }}>
        <img
          className="position-absolute end-0 bottom-10 d-none d-xl-block"
          style={{ height: "350px", zIndex: "2" }}
          src={imgElmBottomRight}
          alt={imgElmBottomRight}></img>

        <div className="container narrower ">
          <h1>Privacy Policy </h1>
          <p
            dir="ltr"
            id="docs-internal-guid-a5e9ccee-7fff-e8dc-d5ee-a7f7a68cf097">
            Birthdai Cards, a service provided by Escapebox, kreativne rešitve,
            d.o.o. ("Birthdai Cards," "we," "us," or "our") values the privacy
            of our users and is committed to safeguarding their information.
            This Privacy Policy describes how we collect, use, and protect your
            personal information when using our online tool to generate and send
            Birthdai Cards. This policy is consistent with applicable United
            States privacy laws.
          </p>
          <br />
          <p dir="ltr">1. Collection of Information</p>
          <p dir="ltr">
            We do not collect personally identifiable information (PII) about
            you when you visit our website or use our online tool to generate a
            birthday card. When creating a card and entering an email address to
            send it to, we only use the email address for the purpose of
            delivering the email and delete it permanently from our system
            immediately after the email has been sent.
          </p>
          <br />
          <p dir="ltr">2. Use of Information</p>
          <p dir="ltr">
            As we do not collect any personal information, we do not use, share,
            or disclose any such information for purposes such as marketing,
            analytics, or research.
          </p>
          <br />
          <p dir="ltr">3. Data Security</p>
          <p dir="ltr">
            We employ reasonable and appropriate security measures to protect
            the information you share with us while generating a birthday card
            on our website. However, please be aware that no method of
            transmission over the Internet or method of electronic storage is
            completely secure. While we strive to protect your information,
            absolute security cannot be guaranteed.
          </p>
          <br />
          <p dir="ltr">4. Children's Privacy</p>
          <p dir="ltr">
            Our website and services are not designed for use by children under
            the age of 13. We do not knowingly collect personal information from
            children under 13 years of age. If we become aware that a child
            under 13 has provided us with any personal information, we will take
            steps to promptly delete such information.
          </p>
          <br />
          <p dir="ltr">5. Changes to this Privacy Policy</p>
          <p dir="ltr">
            We may update this Privacy Policy periodically without prior notice
            to you. We will post any changes on this page and update the
            effective date at the top. Your continued use of our services
            following any changes to this Privacy Policy constitutes your
            acceptance of those changes.
          </p>
          <br />
          <p dir="ltr">6. Contact Information</p>
          <p dir="ltr">
            If you have questions or concerns about this Privacy Policy or our
            privacy practices, please contact us at info@escapebox.si.
          </p>
          <br />
          <p dir="ltr">Birthdai Cards 🎉</p>
          <p dir="ltr">An Escapebox, kreativne rešitve, d.o.o. product</p>
        </div>

        <Footer site="result" />
      </motion.div>
    </motion.div>
  );
};

export default PrivacyPolicy;
