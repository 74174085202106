import React, { useState } from "react";
import { Boracay } from "utils/animations";

const StarRating = ({ onClick, value, explode }) => {
  const [hover, setHover] = useState(0);

  const handleRating = (index) => {
    onClick(index);
  };
  return (
    <div className="star-rating">
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            key={index}
            className={
              index <= (hover || value)
                ? index === value
                  ? "on selected"
                  : "on"
                : "off"
            }
            onClick={() => handleRating(index)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(value)}
          >
            {value && index === value && explode && (
              <div className="star-container">
                <Boracay
                  size={70}
                  stroke={1}
                  rad={35}
                  delay={0}
                  repeatDelay={0}
                  repeat={0}
                  duration={[0.8, 1.6]}
                  color="#9082e1"
                />
              </div>
            )}
            <span className="star">&#9733;</span>
          </button>
        );
      })}
    </div>
  );
};

export default StarRating;
