import { useState, useEffect, useRef } from "react";
import axios from "axios";
//import { Rating } from "react-simple-star-rating";
import ReCAPTCHA from "react-google-recaptcha";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

import { RWebShare } from "react-web-share";

import Footer from "./Footer";

import { trackEvent } from "./utils/tracking";
import { motion } from "framer-motion";
import {
  bounceIn,
  itemFade,
  itemFadeScale,
  staggerItemsFade,
  itemFadeIn,
} from "utils/animations";

// Styles
import "./assets/styles/style.scss";
import imgGift from "./assets/images/icon-gift.svg";

import imgElmLeft from "./assets/images/bg-result-elm-left.svg";
import imgElmBottomRight from "./assets/images/bg-result-elm-bottom-right.svg";
import imgLogoWhite from "./assets/images/logo-white.svg";
import imgLogoTurq from "./assets/images/logo-turq.svg";
import StarRating from "StarRating";

const { Configuration, OpenAIApi } = require("openai");

function Result({ type, socket }) {
  const [data, setData] = useState(null);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingProducts, setIsLoadingProducts] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [rating, setRating] = useState(null);
  const [explode, setExplode] = useState(false);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const [copy, setCopy] = useState({
    value: "",
    copied: false,
  });
  const [products, setProducts] = useState([]);
  const [partialResponse, setPartialResponse] = useState(null);
  const [scheduleTime, setScheduleTime] = useState(new Date());
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  const [isScrollTriggered, setScrollTriggered] = useState(false);
  const productsDiv = useRef(null);

  let { userId } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const recaptchaRef = useRef(null);
  const storyValues = useSelector((state) => state.story.storyValues);
  const hasStoryValues = Object.keys(storyValues).length;

  const sendEmail = async () => {
    setLoading(true);
    //setIsErrorOptin(false);
    const captchaToken =
      localStorage.getItem("captcha") === "success"
        ? "success"
        : await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    axios
      .post(`${process.env.REACT_APP_DOMAIN}/sendEmail`, {
        tomail: email,
        firstname: data.firstname,
        author: data.author,
        userId: userId,
        scheduleTime,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        captchaToken,
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setLoading(false);
          setIsSent(true);
          setIsError(false);
          setEmail("");

          if (isScheduleOpen) {
            window.plausible("email_sent_schedule");
          } else {
            window.plausible("email_sent");
          }
        } else if (response.data.msg === "fail") {
          setIsError(true);
          setLoading(false);
          setIsSent(false);
          setEmail("");
        }
      });
  };

  const getProducts = async (interest, age, gender) => {
    setIsLoadingProducts(true);
    axios
      .get(`${process.env.REACT_APP_DOMAIN}/getProducts`, {
        params: {
          keyword: interest,
          age,
          gender,
        },
      })
      .then((response) => {
        console.log("response", response.data);
        setProducts(response.data);
        // For Plausible tracking
        window.addEventListener("scroll", handleScroll);

        setIsLoadingProducts(false);
      });
  };

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
    setExplode(rate);

    axios
      .post(`${process.env.REACT_APP_DOMAIN}/rate`, {
        jobId: data.jobId,
        rating: rate,
      })
      .then((response) => {
        localStorage.setItem(`rated-${data.jobId}`, true);
        trackEvent("user_interaction", "select", "stars", rate);
      });

    // show thank you message after 1.2 second
    setTimeout(() => {
      setShowThankYouMessage(true);
    }, 1200);
    // other logic
  };

  const copiedText = () => {
    setCopy({ copied: true });
    window.plausible("copied");
  };

  const tryAgain = () => {
    navigate("/");
    window.plausible("try_again");
  };

  const toggleSchedule = () => {
    setIsScheduleOpen(!isScheduleOpen);
  };

  const handleScroll = () => {
    if (!isScrollTriggered) {
      const divPosition = productsDiv.current.getBoundingClientRect();
      const isVisible =
        divPosition.top < window.innerHeight && divPosition.bottom >= 0;

      if (isVisible) {
        window.plausible("scrolled_to_products");

        setScrollTriggered(true);
        window.removeEventListener("scroll", handleScroll);
      }
    }
  };

  useEffect(() => {
    const getResponse = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/getResult/${userId}`
        );

        //console.log(res);
        //console.log(res.data);
        setData(res.data);

        if (hasStoryValues === 0) {
          getProducts(res.data.interest, res.data.age, res.data.gender);
        }

        setCopy({ value: res.data.result.replace(/<\/?[^>]+(>|$)/g, "") });
      } catch (err) {
        console.log("error", err);
      }
    };
    socket.on("job progress", (data) => {
      if (userId === data.jobId) {
        setPartialResponse(data.partial.text);
      }
    });

    socket.on("job completed", (data) => {
      if (userId === data.jobId) {
        console.log(data);
        trackEvent("story_completed", "click", "create_story", "main");
        trackEvent("story_completed", "select", "story", storyValues.style);
        trackEvent("story_completed", "select", "moral", storyValues.tone);

        setTimeout(() => {
          getResponse();
        }, 300);
      }
    });
    console.log(storyValues);
    if (hasStoryValues > 0) {
      getProducts(storyValues.interest1, storyValues.age, storyValues.gender);
    } else {
      getResponse();
    }

    // Outbound links tracking
    function getLinkEl(link) {
      while (
        link &&
        (typeof link.tagName === "undefined" ||
          link.tagName.toLowerCase() !== "a" ||
          !link.href)
      ) {
        link = link.parentNode;
      }
      return link;
    }

    function shouldFollowLink(event, link) {
      // If default has been prevented by an external script, Plausible should not intercept navigation.
      if (event.defaultPrevented) {
        return false;
      }

      var targetsCurrentWindow =
        !link.target || link.target.match(/^_(self|parent|top)$/i);
      var isRegularClick =
        !(event.ctrlKey || event.metaKey || event.shiftKey) &&
        event.type === "click";
      return targetsCurrentWindow && isRegularClick;
    }

    var MIDDLE_MOUSE_BUTTON = 1;

    function handleLinkClick(event) {
      if (event.type === "auxclick" && event.button !== MIDDLE_MOUSE_BUTTON) {
        return;
      }

      var link = getLinkEl(event.target);

      if (link && shouldTrackLink(link)) {
        var eventName = "Cloaked Link: Click";
        var eventProps = { url: link.href, storyID: userId };
        return sendLinkClickEvent(event, link, eventName, eventProps);
      }
    }

    function sendLinkClickEvent(event, link, eventName, eventProps) {
      var followedLink = false;

      function followLink() {
        if (!followedLink) {
          followedLink = true;
          window.location = link.href;
        }
      }

      if (shouldFollowLink(event, link)) {
        window.plausible(eventName, {
          props: eventProps,
          callback: followLink,
        });
        setTimeout(followLink, 5000);
        event.preventDefault();
      } else {
        window.plausible(eventName, { props: eventProps });
      }
    }

    function shouldTrackLink(link) {
      var toBeTracked = "amazon.com";
      return !!link.href.match(toBeTracked);
    }

    document.addEventListener("click", handleLinkClick);
    document.addEventListener("auxclick", handleLinkClick);
  }, []);

  useEffect(() => {
    if (!isScheduleOpen) {
      setScheduleTime(new Date());
    }
  }, [isScheduleOpen]);

  return (
    <motion.div
      variants={staggerItemsFade}
      initial="hidden"
      animate="show"
      exit="exit"
      className="app position-relative">
      <motion.img
        variants={bounceIn}
        className="position-absolute  start-2 top-20 d-none d-xl-block"
        // className="position-absolute h-100 start-2 top-20 d-none d-xl-block"
        style={{ zIndex: "2", height: "200px" }}
        src={imgElmLeft}
        alt={imgElmLeft}
      />
      <motion.div
        variants={itemFade}
        className="hero hero__primary position-relative bg-white text-white text-center pt-3 pt-md-5 pb-6 pb-md-8">
        <motion.img
          variants={itemFade}
          className="position-absolute h-25  "
          style={{ right: "15px", top: "15px", zIndex: "2" }}
          src={imgLogoWhite}
          alt={imgLogoWhite}
        />
      </motion.div>

      <motion.div
        variants={itemFade}
        className="bg-white position-relative"
        style={{ zIndex: "1" }}>
        <img
          className="position-absolute end-0 bottom-10 d-none d-xl-block"
          style={{ height: "350px", zIndex: "2" }}
          src={imgElmBottomRight}
          alt={imgElmBottomRight}></img>

        <div className="container narrower ">
          <motion.div
            variants={itemFade}
            className="border-box p-3 p-md-6 mb-4">
            <div className="text-center">
              <motion.img
                whileHover={{
                  rotate: 360,
                  transition: { duration: 1, ease: "easeInOut" },
                }}
                className="mb-4 "
                src={imgGift}
                alt={imgGift}
              />
            </div>

            {data === null && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ delay: 1, duration: 2 }}
                dangerouslySetInnerHTML={{ __html: partialResponse }}
              />
            )}

            {data !== null && (
              <motion.div
                variants={itemFadeScale}
                initial="hidden"
                animate="show">
                <motion.h1
                  initial={{ scale: 0.8 }}
                  animate={{ scale: [1.2, 1] }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  className="text-pink-dark mb-4 text-center">
                  {t("ResHB")} {data.firstname}!
                </motion.h1>

                <motion.div
                  variants={itemFade}
                  dangerouslySetInnerHTML={{ __html: data.result }}
                />

                <motion.p variants={itemFade} className="mt-6 text-center">
                  {t("ResFrom")}
                  <br />
                  <span className="fw-bold">{data.author}</span>
                </motion.p>
                <motion.div variants={itemFade} className="text-center mt-4">
                  <img src={imgLogoTurq} alt={imgLogoTurq} />
                </motion.div>
              </motion.div>
            )}
          </motion.div>

          <motion.div
            variants={itemFadeScale}
            className="bg-violet-light rounded-2 p-4 position-relative">
            {type === "sender" ? (
              <div>
                {isSent && (
                  <motion.div
                    variants={itemFade}
                    className="success d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      {isScheduleOpen ? (
                        <h3 className="text-violet">
                          Birthdai card is scheduled for:
                          <br />
                          <small>{scheduleTime.toString()}</small>
                        </h3>
                      ) : (
                        <h3 className="text-violet">{t("EmailOnTheWay")}</h3>
                      )}

                      <button
                        onClick={() => setIsSent(false)}
                        className="btn btn-sm bg-violet text-white fw-bold shadow-none px-3 mx-auto"
                        type="button">
                        <span>{t("EmailSendAnother")}</span>
                      </button>
                    </div>
                  </motion.div>
                )}

                {isError && (
                  <div className="success d-flex align-items-center justify-content-center">
                    <div>
                      <h3 className="text-violet">
                        Something went wront. Try again.
                      </h3>

                      <button
                        onClick={() => setIsError(false)}
                        className="btn btn-sm bg-violet text-white fw-bold shadow-none  px-3"
                        type="button">
                        <span>{t("Tagain")}</span>
                      </button>
                    </div>
                  </div>
                )}

                <h3 className="text-blue">{t("SendH")} 🎂</h3>
                <p>{t("SendP")}</p>

                <div className="row g-2">
                  <div className="col-8">
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder={t("SendEmail")}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-4">
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      transition={{
                        type: "spring",
                        stiffness: 400,
                        damping: 10,
                      }}
                      onClick={() => sendEmail()}
                      className="btn btn-primary bg-violet text-white fw-bold w-100 h-100 shadow-none p-0 "
                      type="button"
                      disabled={loading && "disabled"}>
                      {loading && (
                        <span
                          className="spinner-border spinner-border-md me-3"
                          role="status"
                          aria-hidden="true"></span>
                      )}
                      <span>💌 {isScheduleOpen ? "Schedule" : t("Send")}</span>
                    </motion.button>
                  </div>
                  <div className="col-12">
                    <button
                      onClick={() => toggleSchedule()}
                      className="btn btn-sm btn-link text-orange p-0 mb-2"
                      style={{ boxShadow: "none" }}>
                      {isScheduleOpen
                        ? "Close schedule"
                        : "Schedule sending Birthdai Card"}
                    </button>

                    {isScheduleOpen && (
                      <DatePicker
                        selected={scheduleTime}
                        onChange={(date) => setScheduleTime(date)}
                        showTimeSelect
                        timeIntervals={60}
                        dateFormat="MMMM d, yyyy h:00 aa"
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center">
                <h3 className="text-blue">{t("ShareH")}</h3>
                <p>{t("ShareP")}</p>

                <RWebShare
                  data={{
                    text: "Send funny & unique Birthdai 🎂 message to your loved ones!",
                    url: `https://www.birthdaicards.com//happybday/${userId}`,
                    title: "Share Your Birtdai Card",
                    sites: ["facebook, twitter, linkedin"],
                  }}>
                  <button className="btn  bg-violet text-white fw-bold shadow-none px-5">
                    {t("Share")}
                  </button>
                </RWebShare>
              </div>
            )}
          </motion.div>

          {type === "sender" && (
            <div className="text-center mt-3">
              <motion.div
                variants={itemFade}
                className="text-center products p-2 p-md-4">
                <h3 className="text-blue mb-4">
                  {(data !== null && data.author) ||
                    (hasStoryValues > 0 && storyValues.author)}
                  , based on the interests, we found some interesting <br />
                  products you might add to the message 🎂
                </h3>

                <motion.div variants={staggerItemsFade} className="row g-4">
                  {loadingProducts && (
                    <div className="mx-auto">
                      <span
                        className="spinner-border spinner-border-md me-3 text-blue"
                        role="status"
                        aria-hidden="true"></span>
                    </div>
                  )}

                  {/*                   {products.length > 0 && (
                    <h1>{products.request_parameters.search_term}</h1>
                  )} */}

                  {products.length !== 0 && (
                    <>
                      <motion.h3
                        variants={itemFade}
                        className="d-none"
                        ref={productsDiv}>
                        {products.request_parameters.search_term}
                      </motion.h3>
                      {products.search_results.slice(0, 15).map((item) => {
                        return (
                          <motion.div
                            variants={itemFade}
                            className="col-4"
                            key={item.asin}>
                            <a href={`${item.link}`} target="_blank">
                              <div className="item">
                                <div className="square-container mb-2">
                                  <img src={`${item.image}`} />
                                </div>
                                <p className="small fw-bold">{item.title}</p>
                                {item.price !== undefined && (
                                  <p className="small fw-bold text-red">
                                    {item.price.raw}
                                  </p>
                                )}
                              </div>
                            </a>
                          </motion.div>
                        );
                      })}
                    </>
                  )}
                  {}
                </motion.div>
              </motion.div>
            </div>
          )}

          {type === "sender" ? (
            <div className="actions d-flex justify-content-center my-4">
              <div className="row ">
                <div className="col-auto">
                  <CopyToClipboard
                    text={copy.value}
                    onCopy={() => copiedText()}>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      transition={{
                        type: "spring",
                        stiffness: 400,
                        damping: 10,
                      }}
                      className={`btn  bg-violet text-white fw-bold shadow-none px-3 ${
                        copy.copied && "disabled"
                      }`}>
                      {copy.copied ? `✅ ${t("Copied")}` : `📝 ${t("Copy")}`}
                    </motion.button>
                  </CopyToClipboard>
                </div>
                <div className="col-auto">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    transition={{
                      type: "spring",
                      stiffness: 400,
                      damping: 10,
                    }}
                    onClick={() => tryAgain()}
                    className="btn btn-outline-pink-dark text-pink-dark fw-bold shadow-none px-3">
                    🤖 {t("Tagain")}
                  </motion.button>
                </div>
              </div>
            </div>
          ) : (
            <div className="actions d-flex justify-content-center my-4">
              <div className="row ">
                <div className="col-auto">
                  <button
                    onClick={() => navigate("/")}
                    className="btn btn-outline-pink-dark text-pink-dark fw-bold shadow-none px-3">
                    {t("ShareTry")}
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="text-center mt-4">
            <div className="row d-flex align-items-center pt-1">
              {!showThankYouMessage ? (
                <>
                  <p>{t("Rating")}</p>
                  <div className="col-12 mb-4">
                    {/* <Rating onClick={handleRating} /> */}
                    <StarRating
                      onClick={handleRating}
                      value={rating}
                      explode={explode}
                    />
                  </div>
                </>
              ) : (
                <motion.div variants={itemFadeIn} className="col-12 mt-3">
                  <>
                    <h4 className="mb-0">Thank's for your vote!</h4>
                    <small className="d-none">
                      You rated with: <strong>{rating}</strong>
                    </small>
                  </>
                </motion.div>
              )}
            </div>
          </div>
        </div>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
          badge="inline"
        />
        <Footer site="result" />
      </motion.div>
    </motion.div>
  );
}

export default Result;
