import { Link } from "react-router-dom";
import imgLogoEb from "./assets/images/logo-eb.png";
import imgLogo from "./assets/images/logo.svg";

const Footer = ({ site, loading }) => {
  return (
    <div className="position-relative footer hero hero__footer">
      <div className={`wave ${!loading ? "paused" : ""}`}></div>
      <div className={`wave ${!loading ? "paused" : ""}`}></div>

      <div className="row  g-0 d-flex align-items-center position-absolute bottom-0 w-100 px-3">
        <div className="col-12 col-sm-4 text-center text-md-start mb-2 mb-sm-0">
          <p className="small">
            <img style={{ width: "120px" }} src={imgLogo} alt={imgLogo} />
          </p>
        </div>

        <div className="col-12 col-sm-4 text-center mb-2 mb-sm-0">
          <p className="small mb-0 text-white">
            <a
              className="text-white"
              href="https://escapebox.si/en/"
              target="_blank"
              rel="noreferrer">
              Escapebox Gamification
            </a>
            . All Rights Reserved, 2023
            <br />
            <Link to="/privacy" className="text-white">
              Privacy Policy
            </Link>
            &nbsp;|&nbsp;
            <Link to="/terms" className="text-white">
              Terms & Agreements
            </Link>
            &nbsp;|&nbsp;
            <Link to="cookies" className="text-white">
              Cookie Policy
            </Link>
          </p>
          <p style={{ fontSize: "11px" }} className="pt-2">
            This website is a participant in the Amazon Services LLC Associates
            Program, an affiliate advertising program designed to provide a
            means for sites to earn advertising fees by advertising and linking
            to Amazon.com. As an Amazon Associate, we earn from qualifying
            purchases.
          </p>
        </div>
        <div className="col-12 col-sm-4 text-center text-md-end mb-2 mb-sm-0">
          <a href="https://www.escapebox.si" target="_blank" rel="noreferrer">
            <img style={{ width: "100px" }} src={imgLogoEb} alt={imgLogoEb} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
