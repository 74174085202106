import { motion } from "framer-motion";
import {
  bounceIn,
  itemFade,
  itemFadeScale,
  staggerItemsFade,
  itemFadeIn,
} from "utils/animations";
import { Link } from "react-router-dom";
import Footer from "../Footer";

import imgElmLeft from "../assets/images/bg-result-elm-left.svg";
import imgElmBottomRight from "../assets/images/bg-result-elm-bottom-right.svg";
import imgLogoWhite from "../assets/images/logo-white.svg";

const Terms = () => {
  return (
    <motion.div
      variants={staggerItemsFade}
      initial="hidden"
      animate="show"
      exit="exit"
      className="app position-relative">
      <motion.img
        variants={bounceIn}
        className="position-absolute  start-2 top-20 d-none d-xl-block"
        // className="position-absolute h-100 start-2 top-20 d-none d-xl-block"
        style={{ zIndex: "2", height: "200px" }}
        src={imgElmLeft}
        alt={imgElmLeft}
      />
      <Link to="/">
        <motion.div
          variants={itemFade}
          className="hero hero__primary position-relative bg-white text-white text-center pt-3 pt-md-5 pb-6 pb-md-8">
          <motion.img
            variants={itemFade}
            className="position-absolute h-25  "
            style={{ right: "15px", top: "15px", zIndex: "2" }}
            src={imgLogoWhite}
            alt={imgLogoWhite}
          />
        </motion.div>
      </Link>

      <motion.div
        variants={itemFade}
        className="bg-white position-relative"
        style={{ zIndex: "1" }}>
        <img
          className="position-absolute end-0 bottom-10 d-none d-xl-block"
          style={{ height: "350px", zIndex: "2" }}
          src={imgElmBottomRight}
          alt={imgElmBottomRight}></img>

        <div className="container narrower ">
          <h1>Terms & Agreements </h1>
          <p
            dir="ltr"
            id="docs-internal-guid-3b9e545f-7fff-a23e-78f2-6de4afc61314">
            1. Introduction
          </p>
          <p dir="ltr">
            Welcome to Birthdai Cards, a service provided by Escapebox,
            kreativne rešitve d.o.o. (“Company”, “we”, “our”, “us”)!
          </p>
          <p dir="ltr">
            These Terms of Service (“Terms”, “Terms of Service”) govern your use
            of our website located at
            <a href="http://www.birthdaicards.com/">www.birthdaicards.com</a>
            (“Website”) and our related services (collectively, the “Services”).
          </p>
          <p dir="ltr">
            By accessing or using the Services, you (“User”, “you”, “your”)
            agree to be bound by these Terms. If you disagree with any part of
            the Terms, then you may not access the Services.
          </p>
          <p dir="ltr">2. Privacy Policy</p>
          <p dir="ltr">
            We value your privacy and take careful measures to protect your
            data. We do not collect personal data, and any email addresses
            provided for the service are not collected or stored after the
            session. We use Plausible for cookieless analytics and have a
            Facebook Pixel tag installed for analytic purposes. Please refer to
            our Privacy Policy for more information. You agree that they
            constitute part of these Terms.
          </p>
          <p dir="ltr">3. User Accounts</p>
          <p dir="ltr">
            The Services do not require the creation of a user account. Any
            email address provided for the purpose of sending a BirthdaiCard is
            used only for that session and is not stored or collected after the
            session.
          </p>
          <p dir="ltr">4. Prohibited Uses</p>
          <p dir="ltr">
            You may use the Services only for lawful purposes and in accordance
            with these Terms.
          </p>
          <p dir="ltr">5. Intellectual Property</p>
          <p dir="ltr">
            The Services and its original content (excluding content provided by
            users), features and functionality are and will remain the exclusive
            property of Escapebox, kreativne rešitve d.o.o. and its licensors.
            The Services are protected by copyright, trademark, and other laws
            of both the United States and foreign countries.
          </p>
          <p dir="ltr">6. Changes to Terms</p>
          <p dir="ltr">
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. We will provide notice of any significant
            changes.
          </p>
          <p dir="ltr">7. Governing Law &amp; Jurisdiction</p>
          <p dir="ltr">
            These Terms shall be governed and construed in accordance with the
            laws of the United States, without regard to its conflict of law
            provisions.
          </p>
          <p dir="ltr">8. Contact Us</p>
          <p>
            If you have any questions about these Terms, please contact us on
          </p>
          <a href="mailto:info@escapebox.si">info@escapebox.si</a>
          <p>.</p>
        </div>

        <Footer site="result" />
      </motion.div>
    </motion.div>
  );
};

export default Terms;
