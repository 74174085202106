import { useState, useEffect, useReducer } from "react";
import Axios from "axios";

// Styles
import "./assets/styles/style.scss";

function Admin() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function getData() {
    setIsLoading(true);
    try {
      let dataArr = await Axios.get(
        `${process.env.REACT_APP_DOMAIN}/getResultsOld`
      );
      setData(dataArr.data);

      setIsLoading(false);
    } catch (err) {
      console.log(`ERROR: ${err}`);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="App p-5">
      <div className="container text-center">
        {isLoading ? (
          <h1>LOADING ...</h1>
        ) : (
          <table className="table text-start">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Prompt</th>
                <th scope="col">Result</th>
                <th scope="col">Rating</th>
              </tr>
            </thead>
            <tbody>
              {data
                .sort((a, b) => b.created_at.localeCompare(a.created_at))
                .map((item, index) => (
                  <tr key={index}>
                    <th scope="row">{data.length - index}</th>
                    <td>{item.prompt}</td>
                    <td>
                      <div style={{ whiteSpace: "pre-line" }}>
                        {typeof item.result === "object"
                          ? item.result.content
                          : item.result}
                      </div>
                    </td>
                    <td>{item.rating}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default Admin;
