import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  storyValues: {},
};

export const storySlice = createSlice({
  name: "story",
  initialState,
  reducers: {
    setStoryValues: (state, action) => {
      state.storyValues = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setStoryValues } = storySlice.actions;

export default storySlice.reducer;
