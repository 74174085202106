import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import LanguageDetector from "i18next-browser-languagedetector";

/* i18n.on("languageChanged", function (lng) {
  // if the language we switched to is the default language we need to remove the /en from URL
  if (lng === i18n.options.fallbackLng[0]) {
    if (window.location.pathname.includes("/" + i18n.options.fallbackLng[0])) {
      const newUrl = window.location.pathname.replace(
        "/" + i18n.options.fallbackLng[0],
        ""
      );
      window.location.replace(newUrl);
    }
  }
});

const LanguageDetector = {
  type: "languageDetector",
  detect: () => {
    console.log("are you running?");
    return "en";
  },
  init: () => {},
  cacheUserLanguage: () => {},
}; */

i18n
  //.use(LanguageDetector)
  .use(initReactI18next)

  .init({
    fallbackLng: "en",
    detection: {
      order: ["languageDetector", "path"],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
    resources: {
      en: {
        translations: require("./locales/en/translations.json"),
      },
      /*  */
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

i18n.languages = ["en", "sl"];

export default i18n;
