// Reacts & Libs
import { useState, useEffect, useReducer, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setStoryValues } from "./redux/storySlice";
import { nanoid } from "nanoid";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Lottie from "lottie-react";
import ModalImage from "react-modal-image";
import { useTranslation } from "react-i18next";

// Utils
import { importAll } from "./utils/importImages";
import Preloader from "./utils/Preloader.json";
import { dataExclude } from "./data/dataExclude";
import { trackEvent } from "./utils/tracking";

// Components
import Footer from "./Footer";

// Import motion and animations
import { motion } from "framer-motion";
import {
  staggerItemsFade,
  itemFade,
  Boracay,
  rotateIn,
} from "utils/animations";

// Styles
import "./assets/styles/style.scss";

function CardGenerator({ socket }) {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [disabledMsg, setDisabledMsg] = useState("");
  const [error, setError] = useState(false);
  const [explode, setExplode] = useState({});
  const [promptVar, updatePromptVar] = useReducer(
    (prev, next) => {
      const newPromptVar = { ...prev, ...next };

      return newPromptVar;
    },
    {
      gender: "",
      firstname: "",
      age: "",
      interest1: "",
      interest2: "",
      interest3: "",
      tone: "",
      style: "",
      emojis: "",
      fromwho: "",
      author: "",
    }
  );

  const dispatch = useDispatch();

  const handleRadioChangeTone = (e) => {
    const htmlFor = e.target.id;
    setExplode((prevExplode) => ({ ...prevExplode, [htmlFor]: true }));
    updatePromptVar({ tone: e.target.value });
  };

  const handleRadioChangeStyle = (e) => {
    const htmlFor = e.target.id;
    setExplode((prevExplode) => ({ ...prevExplode, [htmlFor]: true }));
    updatePromptVar({ style: e.target.value });
  };

  const handleRadioChangeFrom = (e) => {
    const htmlFor = e.target.id;
    setExplode((prevExplode) => ({ ...prevExplode, [htmlFor]: true }));
    updatePromptVar({ fromwho: e.target.value });
  };

  const handleRadioChangeGender = (e) => {
    const htmlFor = e.target.id;
    setExplode((prevExplode) => ({ ...prevExplode, [htmlFor]: true }));
    updatePromptVar({ gender: e.target.value });
  };

  const handleExplosionComplete = (htmlFor) => {
    setExplode((prevExplode) => ({ ...prevExplode, [htmlFor]: false }));
  };

  const bottomRef = useRef(null);
  const recaptchaRef = useRef(null);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const images = importAll(
    require.context("./assets/images", false, /\.(png|jpe?g|svg)$/)
  );

  const checkRequiredFields = (fields) => {
    const { firstname, age, interest1, tone, style, author } = fields;
    return [firstname, age, interest1, tone, style, author].every((field) =>
      Boolean(field)
    );
  };

  const isNameCursed = (name, forbiddenWords) => {
    return forbiddenWords.some((val) =>
      name.toLowerCase().includes(val.toLowerCase())
    );
  };

  const getCaptchaToken = async (recaptchaRef) => {
    return localStorage.getItem("captcha") === "success"
      ? "success"
      : await recaptchaRef.current.executeAsync();
  };

  const generateText = async () => {
    const { firstname } = promptVar;

    if (!checkRequiredFields(promptVar)) {
      goToStart();
      setDisabledMsg(
        isNameCursed(firstname, dataExclude) ? t("disabledMsg") : true
      );
      console.log("Missing required fields.");
      return;
    }

    try {
      console.log("captchaTokenstart");
      const captchaToken = await getCaptchaToken(recaptchaRef);
      recaptchaRef.current.reset();

      setDisabled(false);
      setLoading(true);

      const res = await axios.post(`${process.env.REACT_APP_DOMAIN}/api`, {
        promptVar,
        captchaToken,
      });

      localStorage.setItem("captcha", "success");
      dispatch(setStoryValues(promptVar));

      navigate(`/result/${res.data.jobId}`);
    } catch (err) {
      console.log("error", err);
      setError(true);
    }
  };

  return (
    <motion.div
      variants={staggerItemsFade}
      initial="hidden"
      animate="show"
      exit="exit"
      className="app">
      <div className="hero hero__primary position-relative text-white text-center pt-3 pt-md-4 pb-6 pb-md-8">
        <motion.img
          variants={itemFade}
          className="position-absolute h-75 top-50 start-0 translate-middle-y d-none d-xl-block "
          src={images["bg-wave-violet-elm-left.svg"]}
          alt={images["bg-wave-violet-elm-left.svg"]}
        />

        <motion.img
          variants={itemFade}
          className="position-absolute h-75 top-50 end-0 translate-middle-y d-none d-xxl-block "
          src={images["bg-wave-violet-elm-right-triangle.svg"]}
          alt={images["bg-wave-violet-elm-right-triangle.svg"]}
        />

        <motion.img
          variants={itemFade}
          className="position-absolute h-75 top-50 end-0 translate-middle-y d-none d-xl-block "
          src={images["bg-wave-violet-elm-right-c.svg"]}
          alt={images["bg-wave-violet-elm-right-c.svg"]}
        />

        <div className="container">
          <motion.img
            variants={itemFade}
            className="logo"
            src={images["logo.svg"]}
            alt={images["logo.svg"]}
          />

          <motion.h2 variants={itemFade} className="mt-5 heroh1">
            {t("HeroH1")}{" "}
          </motion.h2>
          <motion.h3
            variants={itemFade}
            className="fw-normal text-white hero2"
            dangerouslySetInnerHTML={{
              __html: t("HeroH2"),
            }}
          />

          <a href="#tryit">
            <motion.button
              variants={itemFade}
              whileHover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              className="btn btn-yellow fw-bold mt-4 mb-4 mb-md-6 text-violet btn-try">
              {t("HeroCTA")}
            </motion.button>
          </a>
        </div>
      </div>

      <div className="bg-pink text-center">
        <div className="container">
          <motion.div variants={itemFade} className="row ">
            <h2 className="mb-5 text-violet hero-h2">{t("HowH")}</h2>

            <div className="col-6 col-sm-3 text-center">
              <motion.div
                whileHover={{ scale: 1.1, rotate: 7 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                className="ratio ratio-1x1 px-5 px-md-8 mb-3">
                <img
                  src={images["howitworks-01.svg"]}
                  alt={images["howitworks-01.svg"]}
                  className="p-2 p-sm-0"
                />
              </motion.div>

              <p
                className="fw-bold"
                dangerouslySetInnerHTML={{ __html: t("HowUSP1") }}
              />
            </div>
            <div className="col-6 col-sm-3 text-center">
              <motion.div
                whileHover={{ scale: 1.1, rotate: 7 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                className="ratio ratio-1x1 px-5 px-md-8 mb-3">
                <img
                  src={images["howitworks-02.svg"]}
                  alt={images["howitworks-02.svg"]}
                  className="p-3 p-sm-0"
                />
              </motion.div>
              <p
                className="fw-bold"
                dangerouslySetInnerHTML={{ __html: t("HowUSP2") }}
              />
            </div>
            <div className="col-6 col-sm-3 text-center">
              <motion.div
                whileHover={{ scale: 1.1, rotate: 7 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                className="ratio ratio-1x1 px-5 px-md-8 mb-3">
                <img
                  className="p-4 p-xl-0 p-sm-0 p-xxl-2"
                  src={images["howitworks-03.svg"]}
                  alt={images["howitworks-03.svg"]}
                />
              </motion.div>
              <p
                className="fw-bold"
                dangerouslySetInnerHTML={{ __html: t("HowUSP3") }}
              />
            </div>
            <div className="col-6 col-sm-3 text-center">
              <motion.div
                whileHover={{ scale: 1.1, rotate: 7 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                className="ratio ratio-1x1 px-5 px-md-8 mb-3">
                <img
                  src={images["howitworks-04.svg"]}
                  alt={images["howitworks-04.svg"]}
                  className="p-2 p-sm-0"
                />
              </motion.div>
              <p
                className="fw-bold"
                dangerouslySetInnerHTML={{ __html: t("HowUSP4") }}
              />
            </div>
          </motion.div>
        </div>
      </div>

      <div className="hero hero__turq  text-white text-center pt-3 pt-md-4 pb-5 pb-md-9 "></div>

      <div className="bg-turquise position-relative">
        <img
          className="position-absolute h-25 top-50 translate-middle-y start-0  d-none d-xl-block"
          src={images["bg-content-left.svg"]}
          alt={images["bg-content-left.svg"]}
        />

        <img
          className="position-absolute top-10 end-0 d-none d-xl-block"
          style={{ width: "300px" }}
          src={images["bg-content-right-1.svg"]}
          alt={images["bg-content-right-1.svg"]}
        />

        <img
          className="position-absolute  bottom-10 end-0  d-none d-xl-block"
          style={{ zIndex: "2" }}
          src={images["bg-content-right-2.svg"]}
          alt={images["bg-content-right-2.svg"]}
        />

        <div className="container narrow text-center pt-1" id="tryit">
          <div className="form" id="form">
            <div className="my-4 group d-none">
              <div className="mb-3">
                <h3 className="text-blue hero-h2">
                  {t("Let’s start with a gender.")} *
                </h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "Who is the Birthday person you are creating card for?"
                    ),
                  }}
                />
              </div>

              <div className="row d-flex justify-content-center">
                <div className="col-auto">
                  <input
                    type="radio"
                    name="gender"
                    id="female"
                    value="female"
                    checked={promptVar.gender === "female"}
                    onChange={(e) => handleRadioChangeGender(e)}
                  />
                  <label htmlFor="female">
                    <div className="explosion-container">
                      <img src={images["icon-f.svg"]} alt="female" />
                      {explode["female"] && (
                        <Boracay
                          size={150}
                          delay={0}
                          repeatDelay={0}
                          repeat={0}
                          color="yellow"
                          onComplete={() => handleExplosionComplete("female")}
                        />
                      )}
                    </div>
                  </label>
                  <p>{t("Female")}</p>
                </div>
                <div className="col-auto">
                  <input
                    type="radio"
                    name="gender"
                    id="male"
                    value="male"
                    checked={promptVar.gender === "male"}
                    onChange={(e) => handleRadioChangeGender(e)}
                  />
                  <label htmlFor="male">
                    <div className="explosion-container">
                      <img src={images["icon-m.svg"]} alt="male" />
                      {explode["male"] && (
                        <Boracay
                          size={150}
                          delay={0}
                          repeatDelay={0}
                          repeat={0}
                          color="yellow"
                          onComplete={() => handleExplosionComplete("male")}
                        />
                      )}
                    </div>
                  </label>
                  <p>{t("Male")}</p>
                </div>
              </div>
            </div>

            <div className="group px-lg-8">
              <div className="mb-3">
                <h3 className="text-blue hero-h2">{t("Form1H")} *</h3>
                <p dangerouslySetInnerHTML={{ __html: t("Form1P") }} />
              </div>

              <div className="row g-2">
                <div className="col-8">
                  <input
                    type="text"
                    className="form-control"
                    id="firstname"
                    placeholder={t("FormName")}
                    onChange={(e) =>
                      updatePromptVar({ firstname: e.target.value })
                    }
                  />
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    className="form-control"
                    id="age"
                    placeholder={t("FormAge")}
                    onChange={(e) => updatePromptVar({ age: e.target.value })}
                  />
                </div>
              </div>
            </div>

            <div className="my-5 px-lg-5 group">
              <div className="mb-3">
                <h3 className="text-blue hero-h2">{t("Form2H")} *</h3>
                <p dangerouslySetInnerHTML={{ __html: t("Form2P") }} />
              </div>

              <div className="row g-2">
                <div className="col-12 col-sm">
                  <input
                    type="text"
                    className="form-control"
                    id="interest1"
                    placeholder={t("FormPlace1")}
                    onChange={(e) =>
                      updatePromptVar({ interest1: e.target.value })
                    }
                  />
                </div>
                <div className="col-12 col-sm">
                  <input
                    type="text"
                    className="form-control"
                    id="interest2"
                    placeholder={t("FormPlace2")}
                    onChange={(e) =>
                      updatePromptVar({ interest2: e.target.value })
                    }
                  />
                </div>

                <div className="col-12 col-sm">
                  <input
                    type="text"
                    className="form-control"
                    id="interest3"
                    placeholder={t("FormPlace3")}
                    onChange={(e) =>
                      updatePromptVar({ interest3: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="my-5 group">
              <div className="mb-3">
                <h3 className="text-blue hero-h2">{t("Form3H")} *</h3>
                <p dangerouslySetInnerHTML={{ __html: t("Form3P") }} />
              </div>

              <div className="row g-0 g-sm-4 d-flex justify-content-center">
                <div className="col-6 col-md-auto">
                  <input
                    type="radio"
                    name="test"
                    id="friendly"
                    value="a really good friend"
                    checked={promptVar.tone === "a really good friend"}
                    onChange={(e) => handleRadioChangeTone(e)}
                  />
                  <label htmlFor="friendly" className="explode-label">
                    <div className="explosion-container g-0 p-0">
                      <img
                        src={images["icon-standup.svg"]}
                        alt={images["icon-standup.svg"]}
                      />
                      {explode["friendly"] && (
                        <Boracay
                          size={150}
                          delay={0}
                          repeatDelay={0}
                          repeat={0}
                          color="yellow"
                          onComplete={() => handleExplosionComplete("friendly")}
                        />
                      )}
                    </div>
                  </label>
                  <p>{t("Friendly")}</p>
                </div>

                <div className="col-6 col-md-auto">
                  <input
                    type="radio"
                    name="test"
                    id="inspirational"
                    value="a wise greek philosopher"
                    checked={promptVar.tone === "a wise greek philosopher"}
                    onChange={(e) => handleRadioChangeTone(e)}
                  />
                  <label htmlFor="inspirational">
                    <div className="explosion-container g-0 p-0">
                      <img
                        src={images["icon-insp.svg"]}
                        alt={images["icon-insp.svg"]}
                      />
                      {explode["inspirational"] && (
                        <Boracay
                          size={150}
                          delay={0}
                          repeatDelay={0}
                          repeat={0}
                          color="yellow"
                          onComplete={() =>
                            handleExplosionComplete("inspirational")
                          }
                        />
                      )}
                    </div>
                  </label>
                  <p>{t("Inspirational")}</p>
                </div>

                <div className="col-6 col-md-auto">
                  <input
                    type="radio"
                    name="test"
                    id="standup"
                    value="a stand up comedian"
                    checked={promptVar.tone === "a stand up comedian"}
                    onChange={(e) => handleRadioChangeTone(e)}
                  />
                  <label htmlFor="standup">
                    <div className="explosion-container g-0 p-0">
                      <img
                        src={images["icon-funny.svg"]}
                        alt={images["icon-funny.svg"]}
                      />
                      {explode["standup"] && (
                        <Boracay
                          size={150}
                          delay={0}
                          repeatDelay={0}
                          repeat={0}
                          color="yellow"
                          onComplete={() => handleExplosionComplete("standup")}
                        />
                      )}
                    </div>
                  </label>
                  <p>{t("Funny")}</p>
                </div>

                <div className="col-6 col-md-auto">
                  <input
                    type="radio"
                    name="test"
                    id="inlove"
                    value="in love"
                    checked={promptVar.tone === "in love"}
                    onChange={(e) => handleRadioChangeTone(e)}
                  />
                  <label htmlFor="inlove">
                    <div className="explosion-container g-0 p-0">
                      <img
                        src={images["icon-love.svg"]}
                        alt={images["icon-love.svg"]}
                      />
                      {explode["inlove"] && (
                        <Boracay
                          size={150}
                          delay={0}
                          repeatDelay={0}
                          repeat={0}
                          color="yellow"
                          onComplete={() => handleExplosionComplete("inlove")}
                        />
                      )}
                    </div>
                  </label>
                  <p>{t("Romantic")}</p>
                </div>
              </div>
            </div>

            <div className="my-5 group">
              <div className="mb-3">
                <h3 className="text-blue hero-h2">{t("Form4H")} *</h3>
                <p dangerouslySetInnerHTML={{ __html: t("Form4P") }} />
              </div>

              <div className="row d-flex justify-content-center">
                <div className="col-auto">
                  <input
                    type="radio"
                    name="test2"
                    id="classic"
                    value="card message"
                    checked={promptVar.style === "card message"}
                    onChange={(e) => handleRadioChangeStyle(e)}
                  />
                  <label htmlFor="classic">
                    <div className="explosion-container">
                      <img
                        src={images["icon-classic.svg"]}
                        alt={images["icon-classic.svg"]}
                      />
                      {explode["classic"] && (
                        <Boracay
                          size={150}
                          delay={0}
                          repeatDelay={0}
                          repeat={0}
                          color="yellow"
                          onComplete={() => handleExplosionComplete("classic")}
                        />
                      )}
                    </div>
                  </label>
                  <p>{t("Classic card")}</p>
                </div>
                <div className="col-auto">
                  <input
                    type="radio"
                    name="test2"
                    id="poem"
                    value="poem"
                    checked={promptVar.style === "poem"}
                    onChange={(e) => handleRadioChangeStyle(e)}
                  />
                  <label htmlFor="poem">
                    <div className="explosion-container g-0 p-0">
                      <img
                        src={images["icon-poem.svg"]}
                        alt={images["icon-poem.svg"]}
                      />
                      {explode["poem"] && (
                        <Boracay
                          size={150}
                          delay={0}
                          repeatDelay={0}
                          repeat={0}
                          color="yellow"
                          onComplete={() => handleExplosionComplete("poem")}
                        />
                      )}
                    </div>
                  </label>
                  <p>{t("Poem")}</p>
                </div>

                <div className="col-auto">
                  <input
                    type="radio"
                    name="test2"
                    id="advertisement"
                    value="advertisement"
                    checked={promptVar.style === "advertisement"}
                    onChange={(e) => handleRadioChangeStyle(e)}
                  />
                  <label htmlFor="advertisement">
                    <div className="explosion-container">
                      <img
                        src={images["icon-adv.svg"]}
                        alt={images["icon-adv.svg"]}
                      />
                      {explode["advertisement"] && (
                        <Boracay
                          size={150}
                          delay={0}
                          repeatDelay={0}
                          repeat={0}
                          color="yellow"
                          onComplete={() =>
                            handleExplosionComplete("advertisement")
                          }
                        />
                      )}
                    </div>
                  </label>
                  <p>{t("Advertisement")}</p>
                </div>
              </div>
            </div>

            <div className="my-5 group">
              <div className="mb-3">
                <h3 className="text-blue hero-h2">{t("Form6H")} *</h3>
                <p dangerouslySetInnerHTML={{ __html: t("Form6P") }} />
              </div>

              <div className="row d-flex justify-content-center">
                <div className="col-auto">
                  <input
                    type="radio"
                    name="test3"
                    id="me"
                    value="me"
                    checked={promptVar.fromwho === "me"}
                    onChange={(e) => handleRadioChangeFrom(e)}
                  />
                  <label htmlFor="me">
                    <div className="explosion-container">
                      <img
                        src={images["icon-fromme.svg"]}
                        alt={images["icon-fromme.svg"]}
                      />
                      {explode["me"] && (
                        <Boracay
                          size={150}
                          delay={0}
                          repeatDelay={0}
                          repeat={0}
                          color="yellow"
                          onComplete={() => handleExplosionComplete("me")}
                        />
                      )}
                    </div>
                  </label>

                  <p dangerouslySetInnerHTML={{ __html: t("FormFromMe") }} />
                </div>
                <div className="col-auto">
                  <input
                    type="radio"
                    name="test3"
                    id="us"
                    value="us"
                    checked={promptVar.fromwho === "us"}
                    onChange={(e) => handleRadioChangeFrom(e)}
                  />
                  <label htmlFor="us">
                    <div className="explosion-container">
                      <img
                        src={images["icon-fromus.svg"]}
                        alt={images["icon-fromus.svg"]}
                      />
                      {explode["us"] && (
                        <Boracay
                          size={150}
                          delay={0}
                          repeatDelay={0}
                          repeat={0}
                          color="yellow"
                          onComplete={() => handleExplosionComplete("us")}
                        />
                      )}
                    </div>
                  </label>
                  <p dangerouslySetInnerHTML={{ __html: t("FormFromUs") }} />
                </div>
              </div>
            </div>

            <div className="my-5 group">
              <div className="mb-3">
                <h3 className="text-blue hero-h2">{t("Form7H")} *</h3>
                <p dangerouslySetInnerHTML={{ __html: t("Form7P") }} />
              </div>

              <div className="row d-flex justify-content-center">
                <div className="col-12 col-sm-6">
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    placeholder={t("Form6Place")}
                    rows="3"
                    onChange={(e) =>
                      updatePromptVar({ author: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row d-flex justify-content-center mt-4">
              <p dangerouslySetInnerHTML={{ __html: t("FormBottom") }} />
              <div className="col-12 col-sm-6" ref={bottomRef}>
                <button
                  onClick={() => generateText(prompt)}
                  className={`btn pt-3 fw-bold w-100 d-flex align-items-center justify-content-center btn-generate `}
                  type="button"
                  disabled={loading && "disabled"}>
                  {loading && (
                    <span
                      className="spinner-border spinner-border-md me-3"
                      role="status"
                      aria-hidden="true"></span>
                  )}
                  <span>
                    {loading ? t("FormGenerating") : t("FormGenerate")}
                  </span>
                </button>

                {disabled && (
                  <p className="small mt-2 fw-bold text-pink-dark">
                    {t("FormMand")}
                  </p>
                )}
              </div>
            </div>
            <div className="text-center d-inline-block mt-3">
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                badge="inline"
              />
            </div>
          </div>
        </div>

        <div className="stories">
          <div className="container text-center pt-1">
            <h3 className="hero-h2 text-blue pt-5 pt-sm-7 pb-3">
              See Birthdai Cards Examples
            </h3>
            <div className="row">
              <div className="col-4">
                <ModalImage
                  className="w-100"
                  small={images["story-1.png"]}
                  large={images["story-1.png"]}
                  alt=""
                  hideDownload
                  hideZoom
                  imageBackgroundColor="none"
                />
              </div>

              <div className="col-4">
                <ModalImage
                  className="w-100"
                  small={images["story-2.png"]}
                  large={images["story-2.png"]}
                  alt=""
                  hideDownload
                  hideZoom
                  imageBackgroundColor="none"
                />
              </div>

              <div className="col-4">
                <ModalImage
                  className="w-100"
                  small={images["story-3.png"]}
                  large={images["story-3.png"]}
                  alt=""
                  hideDownload
                  hideZoom
                  imageBackgroundColor="none"
                />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>

      {loading && (
        <div className="loader d-flex align-items-center justify-content-center">
          <Lottie animationData={Preloader} loop={true} />
        </div>
      )}
    </motion.div>
  );
}

function goToStart() {
  // Get the section element by ID
  const section = document.getElementById("form");

  // Scroll to the section using scrollIntoView()
  section.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });

  // Prevent the default link behavior
  return false;
}

export default CardGenerator;
