import React, { useState } from "react";
import axios from "axios";

function Login() {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/login`,
        { password }
      );

      localStorage.setItem("token", response.data.token);
      window.location.href = "/admin"; // Redirect to dashboard page
    } catch (error) {
      console.log("err", error);
    }
  };

  return (
    <div className="vh-100 d-flex align-items-center justify-content-center">
      <form onSubmit={handleSubmit}>
        <div className="m-2">
          <input
            type="password"
            id="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>
        <div className="m-2">
          <button type="submit">Login</button>
        </div>
        {error && <p>{error}</p>}
      </form>
    </div>
  );
}

export default Login;
