import { motion } from "framer-motion";
import {
  bounceIn,
  itemFade,
  itemFadeScale,
  staggerItemsFade,
  itemFadeIn,
} from "utils/animations";

import Footer from "../Footer";
import { Link } from "react-router-dom";
import imgElmLeft from "../assets/images/bg-result-elm-left.svg";
import imgElmBottomRight from "../assets/images/bg-result-elm-bottom-right.svg";
import imgLogoWhite from "../assets/images/logo-white.svg";

const CookiePolicy = () => {
  return (
    <motion.div
      variants={staggerItemsFade}
      initial="hidden"
      animate="show"
      exit="exit"
      className="app position-relative">
      <motion.img
        variants={bounceIn}
        className="position-absolute  start-2 top-20 d-none d-xl-block"
        // className="position-absolute h-100 start-2 top-20 d-none d-xl-block"
        style={{ zIndex: "2", height: "200px" }}
        src={imgElmLeft}
        alt={imgElmLeft}
      />

      <Link to="/">
        <motion.div
          variants={itemFade}
          className="hero hero__primary position-relative bg-white text-white text-center pt-3 pt-md-5 pb-6 pb-md-8">
          <motion.img
            variants={itemFade}
            className="position-absolute h-25  "
            style={{ right: "15px", top: "15px", zIndex: "2" }}
            src={imgLogoWhite}
            alt={imgLogoWhite}
          />
        </motion.div>
      </Link>

      <motion.div
        variants={itemFade}
        className="bg-white position-relative"
        style={{ zIndex: "1" }}>
        <img
          className="position-absolute end-0 bottom-10 d-none d-xl-block"
          style={{ height: "350px", zIndex: "2" }}
          src={imgElmBottomRight}
          alt={imgElmBottomRight}></img>

        <div className="container narrower ">
          <h1>Cookie Policy </h1>
          <p
            dir="ltr"
            id="docs-internal-guid-d4ebd389-7fff-342c-fba4-6fe98cfd4a54">
            Birthdai Cards, a service provided by Escapebox, kreativne rešitve,
            d.o.o. ("Birthdai Cards," "we," "us," or "our"), understands the
            importance of transparency regarding the use of cookies and online
            advertising technology. This Ad and Cookie Policy outlines how we
            use cookieless analytics tools and how we utilize Facebook
            Conversion Tracking Pixel on our website, BirthdaiCards.com.
          </p>
          <br />
          <p dir="ltr">1. Cookieless Analytics</p>
          <p dir="ltr">
            We utilize Plausible, a cookieless analytics tool, to collect
            anonymous usage statistics for our website. Plausible does not use
            cookies or collect any personal data, ensuring the privacy of our
            users. By not using cookies, we can provide you with an improved
            user experience while respecting your privacy. Plausible helps us to
            understand how visitors interact with our website, so we can
            continually improve our services and offerings.
          </p>
          <br />
          <p dir="ltr">2. Facebook Conversion Tracking Pixel</p>
          <p dir="ltr">
            We have implemented a Facebook Conversion Tracking Pixel on our
            website. This pixel allows us to track user behavior after they have
            been redirected to our website by clicking on a Facebook
            advertisement. The collected data is anonymous and cannot be used to
            personally identify users. However, this information helps us
            measure the effectiveness of our advertising campaigns on Facebook,
            optimize ad targeting, and understand the actions taken on our
            website after users have viewed our ads on Facebook.
          </p>
          <br />
          <p dir="ltr">3. Opting Out of Facebook Conversion Tracking Pixel</p>
          <p dir="ltr">
            If you want to opt-out of Facebook Conversion Tracking Pixel, you
            can adjust your ad preferences in your Facebook settings. By doing
            so, you will no longer receive targeted ads based on your browsing
            behavior. Please note that opting out of Facebook Conversion
            Tracking Pixel does not prevent you from seeing our ads on Facebook;
            it simply means that the ads you see may be less relevant to your
            interests.
          </p>
          <br />
          <p dir="ltr">4. Changes to this Ad and Cookie Policy</p>
          <p dir="ltr">
            We may update this Ad and Cookie Policy from time to time without
            prior notice to you. We will post any changes on this page and
            update the effective date at the top. Your continued use of our
            services following any changes to this Ad and Cookie Policy
            constitutes your acceptance of those changes.
          </p>
          <br />
          <p dir="ltr">5. Contact Information</p>
          <p dir="ltr">
            If you have questions or concerns about this Ad and Cookie Policy or
            our related practices, please contact us at info@escapebox.si.
          </p>
          <br />
          <p dir="ltr">Birthdai Cards 🎉</p>
          <p dir="ltr">An Escapebox, kreativne rešitve, d.o.o. product</p>
        </div>

        <Footer site="result" />
      </motion.div>
    </motion.div>
  );
};

export default CookiePolicy;
